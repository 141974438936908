@import url(https://fonts.googleapis.com/css?family=Inter:regular,semi-bold,medium,bold,italic&subset=latin,latin-ext&display=swap);
@font-face {
    font-family: "Juana";
    font-style: normal;
    font-weight: 100;
    src: url(../../static/media/font.04af6cc5.woff2) format('woff2'), url(../../static/media/font.69c6bd8c.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: normal;
    font-weight: 200;
    src: url(../../static/media/font.c6c916c8.woff2) format('woff2'), url(../../static/media/font.ae78e43b.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 300;
    src: url(../../static/media/font.69b797ba.woff2) format('woff2'), url(../../static/media/font.c2fdbff7.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: normal;
    font-weight: 300;
    src: url(../../static/media/font.c6b01e88.woff2) format('woff2'), url(../../static/media/font.34d2be1f.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: bold;
    font-weight: 700;
    src: url(../../static/media/font.bab47141.woff2) format('woff2'), url(../../static/media/font.ec585fed.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: bold;
    font-weight: 600;
    src: url(../../static/media/font.237139af.woff2) format('woff2'), url(../../static/media/font.9007ae7d.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: bold;
    font-weight: 520;
    src: url(../../static/media/font.6161ef1e.woff2) format('woff2'), url(../../static/media/font.8a2a086b.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: normal;
    font-weight: 470;
    src: url(../../static/media/font.2eee2391.woff2) format('woff2'), url(../../static/media/font.aae57a87.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 100;
    src: url(../../static/media/font.bc8d7f5f.woff2) format('woff2'), url(../../static/media/font.1ebacd6c.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: normal;
    font-weight: 400;
    src: url(../../static/media/font.0de735dc.woff2) format('woff2'), url(../../static/media/font.9de0acc3.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: normal;
    font-weight: 200;
    src: url(../../static/media/font.357df4b1.woff2) format('woff2'), url(../../static/media/font.4bbd1c7b.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: normal;
    font-weight: 300;
    src: url(../../static/media/font.0f428a45.woff2) format('woff2'), url(../../static/media/font.03af34e4.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: normal;
    font-weight: 100;
    src: url(../../static/media/font.5cdcc9b0.woff2) format('woff2'), url(../../static/media/font.de8c6bb6.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 700;
    src: url(../../static/media/font.ba3c992b.woff2) format('woff2'), url(../../static/media/font.9ce966d2.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 600;
    src: url(../../static/media/font.c272aacf.woff2) format('woff2'), url(../../static/media/font.5909797b.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: bold;
    font-weight: 700;
    src: url(../../static/media/font.228ac184.woff2) format('woff2'), url(../../static/media/font.251652d5.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 520;
    src: url(../../static/media/font.144bdcbb.woff2) format('woff2'), url(../../static/media/font.1e3946f4.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: bold;
    font-weight: 600;
    src: url(../../static/media/font.44f52d51.woff2) format('woff2'), url(../../static/media/font.e5144f87.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 470;
    src: url(../../static/media/font.0e55bd75.woff2) format('woff2'), url(../../static/media/font.9ec1aec4.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: bold;
    font-weight: 520;
    src: url(../../static/media/font.5713c4f1.woff2) format('woff2'), url(../../static/media/font.05f2a9c5.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: normal;
    font-weight: 470;
    src: url(../../static/media/font.84c8c99c.woff2) format('woff2'), url(../../static/media/font.489a85d8.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 400;
    src: url(../../static/media/font.8093416a.woff2) format('woff2'), url(../../static/media/font.1ce5a8da.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: normal;
    font-weight: 400;
    src: url(../../static/media/font.2b1b4e15.woff2) format('woff2'), url(../../static/media/font.bc67b3db.woff) format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 300;
    src: url(../../static/media/font.b531135c.woff2) format('woff2'), url(../../static/media/font.18c10c3f.woff) format('woff');
    font-display: swap;
 }
  
html {
  width: 100%;
  background-color: #1E1E22;
}

body {
  margin: 0;
  font-family: 'inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
}

h2 {
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
}

.horizontalStack {
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  display: flex;
  position: relative;
}

.verticalStack {
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

#displayOnlyOnLargeScreenArrow, #displayOnlyOnLargeScreen {
  transition: opacity 0.2s;
}

@media screen and (max-height: 482px) {
  #displayOnlyOnLargeScreen {
    opacity: 0;
  }
}

@media screen and (max-height: 318px) {
  #displayOnlyOnLargeScreenArrow {
    opacity: 0;
  }
}
