@import url('./fonts/fonts.css');
@import url('https://fonts.googleapis.com/css?family=Inter:regular,semi-bold,medium,bold,italic&subset=latin,latin-ext&display=swap');

html {
  width: 100%;
  background-color: #1E1E22;
}

body {
  margin: 0;
  font-family: 'inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

h2 {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.horizontalStack {
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  display: flex;
  position: relative;
}

.verticalStack {
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

#displayOnlyOnLargeScreenArrow, #displayOnlyOnLargeScreen {
  transition: opacity 0.2s;
}

@media screen and (max-height: 482px) {
  #displayOnlyOnLargeScreen {
    opacity: 0;
  }
}

@media screen and (max-height: 318px) {
  #displayOnlyOnLargeScreenArrow {
    opacity: 0;
  }
}