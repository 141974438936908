@font-face {
    font-family: "Juana";
    font-style: normal;
    font-weight: 100;
    src: url('JuanaExtraLight/font.woff2') format('woff2'), url('JuanaExtraLight/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: normal;
    font-weight: 200;
    src: url('JuanaLight/font.woff2') format('woff2'), url('JuanaLight/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 300;
    src: url('JuanaThinIt/font.woff2') format('woff2'), url('JuanaThinIt/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: normal;
    font-weight: 300;
    src: url('JuanaThin/font.woff2') format('woff2'), url('JuanaThin/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: bold;
    font-weight: 700;
    src: url('JuanaAltBlack/font.woff2') format('woff2'), url('JuanaAltBlack/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: bold;
    font-weight: 600;
    src: url('JuanaAltBold/font.woff2') format('woff2'), url('JuanaAltBold/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: bold;
    font-weight: 520;
    src: url('JuanaAltSemiBold/font.woff2') format('woff2'), url('JuanaAltSemiBold/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: normal;
    font-weight: 470;
    src: url('JuanaAltMedium/font.woff2') format('woff2'), url('JuanaAltMedium/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 100;
    src: url('JuanaExtraLightIt/font.woff2') format('woff2'), url('JuanaExtraLightIt/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: normal;
    font-weight: 400;
    src: url('JuanaAltRegular/font.woff2') format('woff2'), url('JuanaAltRegular/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: normal;
    font-weight: 200;
    src: url('JuanaAltLight/font.woff2') format('woff2'), url('JuanaAltLight/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: normal;
    font-weight: 300;
    src: url('JuanaAltThin/font.woff2') format('woff2'), url('JuanaAltThin/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "JuanaAlt";
    font-style: normal;
    font-weight: 100;
    src: url('JuanaAltExtraLight/font.woff2') format('woff2'), url('JuanaAltExtraLight/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 700;
    src: url('JuanaBlackIt/font.woff2') format('woff2'), url('JuanaBlackIt/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 600;
    src: url('JuanaBoldIt/font.woff2') format('woff2'), url('JuanaBoldIt/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: bold;
    font-weight: 700;
    src: url('JuanaBlack/font.woff2') format('woff2'), url('JuanaBlack/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 520;
    src: url('JuanaSemiBoldIt/font.woff2') format('woff2'), url('JuanaSemiBoldIt/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: bold;
    font-weight: 600;
    src: url('JuanaBold/font.woff2') format('woff2'), url('JuanaBold/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 470;
    src: url('JuanaMediumIt/font.woff2') format('woff2'), url('JuanaMediumIt/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: bold;
    font-weight: 520;
    src: url('JuanaSemiBold/font.woff2') format('woff2'), url('JuanaSemiBold/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: normal;
    font-weight: 470;
    src: url('JuanaMedium/font.woff2') format('woff2'), url('JuanaMedium/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 400;
    src: url('JuanaRegularIt/font.woff2') format('woff2'), url('JuanaRegularIt/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: normal;
    font-weight: 400;
    src: url('JuanaRegular/font.woff2') format('woff2'), url('JuanaRegular/font.woff') format('woff');
    font-display: swap;
 }
  @font-face {
    font-family: "Juana";
    font-style: italic;
    font-weight: 300;
    src: url('JuanaLightIt/font.woff2') format('woff2'), url('JuanaLightIt/font.woff') format('woff');
    font-display: swap;
 }
  